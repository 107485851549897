@import "~@/styles/variables";
































.stripe-bank-account p {
  margin-bottom: 0.25rem;
}
