@import "~@/styles/variables";





















































































































.actions {
  margin-left: -#{$btn-link-padding-x};

  .default {
    opacity: 1;
  }
}
