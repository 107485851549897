@import "~@/styles/variables";








































.stripe-card p {
  margin-bottom: 0.25rem;
}
