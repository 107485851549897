@import "~@/styles/variables";



























































































































































































































































































































































.edit-insurance .invalid-feedback {
  /* allows \n for new lines */
  white-space: pre-line;
}

.edit-start-checkbox label {
  font-size: 1em;
}
